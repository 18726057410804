<template>
    
  <div class="margin-page">
    
    <div id="d1" style="display:none;z-index: 1000;position: relative;">
      <ModalAlwaysShow
        tipo='error'
        :messageErr="$t('default.tempoExcedido')"
        :detailErr="$t('default.tempExcedidoSelectAccount')"
      />
    </div>

    <ExceptionModalOK
      ref="exceptionWarnigOK"
      tipo='error'
      :messageErr="$t('default.failValidlab')"
      :detailErr="$t('default.failValideDet')"
    />
    <ExceptionModal
      ref="exceptionWarnig"
      tipo='error'
      :messageErr="$t('default.messageErrFetchingData')"
      :detailErr="$t('default.deatilErrFetchingDataConsult')"
    />
    <ExceptionModal
      ref="exceptionError"
      id="testeid"
      tipo='error'
      :messageErr="$t('default.tempoExcedido')"
      :detailErr="$t('default.tempoExcedidoPag')"
    />

  </div>
</template>

<script>
import { getWithExpiry, setWithExpiry} from '@/util/util.js';

export default {
  data(){
    return {
      msg:""
    }
  },
  methods:{
    showWarningModal(){
      this.$refs.exceptionWarnig.enable();
    },
  },
  created(){

    this.$root.$refs.loadingModal.enable();
    const url = window.location.href;
    var err = '';

    if(url.includes('error')){
      err ='erro';
    }
    
    var parts2 = url.split("access_token=");
    var parts = parts2[1].split("&");
    var token = parts[0];
    this.msg = token;
    const expireIn = 300000;

    var id_token = url.split("id_token=")[1].split("&")[0];

    this.$root.$refs.loadingModal.disable();

    if(err == "erro"){
      this.$router.push({
        name: 'ManagerLogin',
        params: { err: 'invalid'}
      });
    }
    else{
      setWithExpiry('token', token, expireIn);
      setWithExpiry('id_token', id_token, expireIn);
      this.$router.push({
        name: 'ManagerConsultConsent',
        params: { value: token, id_token: id_token }
      });
    }
  }
}

</script>

<style>

.margin-page{
  padding-left: 11px;
  padding-right: 18px;
  padding-top: 10px
}
.titlecss{
  font-family: Open Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  text-align: left;
  padding-left: 13px;
  color: var(--tecban-text-black);
}
.separator{
  padding-bottom: 16px;
}

</style>          